/* global Component, __lodash__ */
class orderActionsComponent extends Component {

    static name() {
        return "orderActionsComponent";
    }

    static componentName() {
        return "orderActionsComponent";
    }

    getProps() {
        return  ['data', 'index', 'column'];
    }

    data() {
        return {
            FavouritesOrders:[],
        };
    }

    getComputed() {
        return {
            orderSerNr:function (){
                return window.btoa(this.data.SerNr);
            },
            viewFilter: function () {
                return this.$store.getters.getOrderView;
            },
            FavouritesOrdersSerNrs: function () {
                return __lodash__.map([...this.FavouritesOrders], x => {
                    return x[2];
                });

            },
            canByCopy: function () {
                if(this.isUserOnlyModifier())
                    return false;
                return true;
            },
            canByLiberate: function () {
                if (this.viewFilter == 'awaiting' && this.data.OrderStatus == this.$store.getters.getSetting.PreAutorizationStatus && (this.isUserLiberator() || this.isAdmin))
                    return true;
                return false;
            },
        };
    }

    getMethods() {
        return {
            isUserLiberator:this.isUserLiberator,
            isUserOnlyModifier:this.isUserOnlyModifier,
            orderAction:this.orderAction,
            orderPay:this.orderPay,
            orderPayed:this.orderPayed,
            copyOrder:this.copyOrder,
            viewOrder:this.viewOrder,
            OpenModalFavoriteOrders: this.OpenModalFavoriteOrders,
            downloadDocument:this.downloadDocument,
            toggleFavouriteOrder:this.toggleFavouriteOrder,
            isFavouriteOrder:this.isFavouriteOrder,
            FavouriteOrder:this.FavouriteOrder,
            updatefavouritesOrders:this.updatefavouritesOrders
        };
    }

    isUserLiberator(){
        if(this.$store.getters.getIsLogin)
            return this.$store.state.liberatorCodes.indexOf(this.data.Contact) != -1 || this.$store.state.CenterLiberators.indexOf(this.data.CenterCost) != -1;
        return false;
    }

    isUserOnlyModifier() {
        if(this.$store.getters.getIsLogin)
            return this.$store.state.onlyModifiedCodes.indexOf(this.data.Contact) != -1 || this.$store.state.CenterOnlyModifiedCodes.indexOf(this.data.CenterCost) != -1;
        return false;
    }

    orderAction(action) {
        this.$store.dispatch('orderAction', {action: action, sernr: this.data.SerNr, date: this.data.TransDate});
    }

    orderPay(){
        return this.$store.getters.getSetting.PayTermRow.filter(x => (x.fields.RequireOnlinePayment && x.fields.PayTermCode === this.data.PayTerm)).length > 0;
    }

    orderPayed(){
        if(this.data.PaymentStatus && this.data.PaymentStatusCode) {
            let payStatus = this.data.PaymentStatus.split(",");
            let payStatusCode = this.data.PaymentStatusCode.split(",");
            if (payStatus.indexOf("1") != -1 && payStatusCode.indexOf("approved") != -1)
                return true;
            if (payStatus.indexOf("4") != -1 && payStatusCode.indexOf("in_process") != -1)
                return true;
        }
        return false;
    }

    copyOrder() {
        //console.log('custom-actions: ', this.data, this.index, this.column);
        let self = this;
        this.$store.dispatch('toogleLockScreen',this.tr('Load Items to Shopping Car'));
        this.$store.dispatch('copyOrder', {orderId: this.data.SerNr}).then(function (){
            self.$store.dispatch('toogleLockScreen',"").then(function (){
                self.$router.push("/badges");
            });
        });
    }

    viewOrder() {
        this.emitEvent('viewOrderDetail',this.data.SerNr);
        //this.$router.replace({path: '/profile', query:{order: this.data.SerNr}});
    }

    async OpenModalFavoriteOrders() {
        await this.$store.dispatch('orderDetail', this.data.SerNr);
    }

    downloadDocument(){
        let params =  this.encode(`RecordName=SalesOrder&InternalId=${this.data.internalId}`);
        //console.log('custom-actions: ', this.data);
        window.open('../oo/api/get_pdfdocument?' + params,"_blank");
    }

    toggleFavouriteOrder(){
        this.$store.dispatch('toggleFavouriteOrder', this.FavouriteOrder(this.data.SerNr));
    }

    isFavouriteOrder(){
        return (this.FavouritesOrdersSerNrs.includes(this.data.SerNr.toString())) ? true : false;
    }

    FavouriteOrder(SerNr) {
        let FV =  this.FavouritesOrders.find(x=> x[2] == SerNr);
        return {
            name: FV[0],
            items: FV[1],
            OrderSerNr: FV[2],
            from: 'so-items'
        };
    }

    updatefavouritesOrders(){
        this.FavouritesOrders = this.$store.getters.getAllFavouritesOrders;
    }

    getWatch() {
        return {
            '$store.state.favouritesOrders':'updatefavouritesOrders'
        };
    }

    created() {
        return function () {
            this.FavouritesOrders = this.$store.getters.getAllFavouritesOrders;
        };
    }

    getTemplate() {
        return`<div :key="'actions-'+data.SerNr" class="btn-group custom-actions" role="group" aria-label="Button group with nested dropdown">
                  <div class="btn-group" role="group">
                     <a  class="btn" :key="'actions-RemoveFromFavorite-'+data.SerNr" v-show="isFavouriteOrder()" :title="tr('Remove from Favorite')" @click="toggleFavouriteOrder()">
                        <i class="is-favorite fas fa-star"></i>
                      </a>
                      <template v-if="orderPay()">
                          <router-link  :to="'/payment/'+orderSerNr" class="btn" :key="'actions-Payment-'+data.SerNr" v-if="!orderPayed()" :title="tr('Pay Order')" >
                              <i class="far fa-credit-card"></i>
                          </router-link>
                      </template>
                      <a class="btn"  :key="'actions-addToFavorite-'+data.SerNr" v-show="!isFavouriteOrder()" :title="tr('Add To Favorite')" data-toggle="modal" data-target="#addSOFavModal" @click="OpenModalFavoriteOrders()">
                        <i class="far fa-star"></i>
                      </a>
                      <a class="dropdown-item"  :key="'actions-copy-'+data.SerNr"  :title="tr('Copy')" @click.stop="copyOrder()" v-if="canByCopy">
                        <i class="fa fa-copy"></i>
                      </a>
                      <a class="dropdown-item" :key="'actions-edit-'+data.SerNr" :title="tr('View')+'/'+tr('Edit')" @click="viewOrder()">
                        <template v-if="data.Status!=1">
                            <i class="fa fa-edit"></i>
                        </template>
                        <template v-else>
                            <i class="fa fa-eye"></i>
                        </template>
                      </a>
                      <a class="dropdown-item"  :key="'actions-download-'+data.SerNr"  target="_blank" :title="tr('Download')"  @click="downloadDocument()"  v-if="viewFilter!='awaiting'">
                        <i class="fa fa-download"></i>
                      </a>
                      <a class="dropdown-item" :key="'actions-liberate-'+data.SerNr"  :title="tr('Confirm')" @click.stop="orderAction('liberate')" v-if="canByLiberate">
                        <i class="fa fa-check"></i>
                      </a>
                      <a class="dropdown-item" :key="'actions-reject-'+data.SerNr"  :title="tr('Reject')" @click.stop="orderAction('reject')" v-if="canByLiberate">
                        <i class="fa fa-minus"></i>
                      </a>
                    </div>
                </div>`;
    }
}

orderActionsComponent.registerComponent();